import { makeStyles, createStyles } from '@material-ui/core/styles';
import { darken } from 'polished';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '95%',
      margin: '0 auto',
    },
    header: {
      '&>th': {
        color: '#343a40',
        cursor: 'pointer',
        fontWeight: 600,
      },
    },
    body: {
      '&>tr': {
        cursor: 'pointer',
      },
      '&>tr, td': {
        color: '#343a40',
      },
      '&>tr:nth-child(odd)': {
        background: '#f7fbff',
      },
      '&>tr:nth-child(odd):hover': {
        background: darken(0.02, '#f7fbff'),
        transition: 'background 0.3s ease',
      },
      '&>tr:nth-child(even):hover': {
        background: '#f7fbff',
        transition: 'background 0.3s ease',
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      margin: '20px 0',
      flexWrap: 'wrap',
      width: '100%',
    },
  }),
);
